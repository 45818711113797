<div class="d-flex flex-column ml-3 mr-3">
  <button class="btn btn-secondary" (click)="moveRowUp.emit(reference)">
    Up
  </button>
  <button class="btn btn-secondary" (click)="moveRowDown.emit(reference)">
    Down
  </button>
  <button class="btn btn-danger" (click)="removeRow.emit(reference)">
    Remove
  </button>
</div>
