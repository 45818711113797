<ng-template #createRequestModalBody>
  <rr-overlay-spinner
    [spinnerStatus]="createRequestModalStore.loadingState$ | async"
  ></rr-overlay-spinner>

  <div class="modal-body m-3 main-modal">
    <app-create-garment-2d
      *ngIf="
        (createRequestModalStore.productType$ | async) ===
        allProductTypes.GARMENT_2D
      "
    ></app-create-garment-2d>
    <app-create-garment-3d-cad
      *ngIf="
        (createRequestModalStore.productType$ | async) ===
        allProductTypes.GARMENT_3D_CAD
      "
    ></app-create-garment-3d-cad>
    <app-create-outfit-2d
      *ngIf="
        (createRequestModalStore.productType$ | async) ===
        allProductTypes.OUTFIT_2D
      "
    ></app-create-outfit-2d>
    <app-create-outfit-3d
      *ngIf="
        (createRequestModalStore.productType$ | async) ===
        allProductTypes.OUTFIT_3D
      "
    ></app-create-outfit-3d>
  </div>
</ng-template>
