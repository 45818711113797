<ng-template #addGarments>
  <app-add-garment-modal
    [productType]="createRequestModalStore.productType$ | async"
  ></app-add-garment-modal>
</ng-template>
<ng-template #recentlyUsedGarments>
  <div class="recently-used-garments" *ngIf="showRecenltyUsedDialog">
    <div class="recenlty-used-title">
      <span>Recently used</span>
    </div>
    <div
      class="item-list d-flex flex-column"
      [ngStyle]="{ 'max-height': recenltyUsedMaxHeight + 'px' }"
    >
      <rr-overlay-spinner
        [spinnerStatus]="spinnerStatusRecenltyUsed"
      ></rr-overlay-spinner>
      <div
        class="recently-used-garment"
        *ngFor="let garment of garmentToDisplay"
        (click)="recentlyUsedGarmentClicked(garment)"
      >
        <app-configuration-display
          [garment]="garment"
        ></app-configuration-display>
      </div>
    </div>
    <div
      class="all-garments-action d-flex flex-row"
      (click)="addGarmentsClicked()"
    >
      <span>All Garments</span>
      <span class="rr-arrow-right ml-auto"></span>
    </div>
  </div>
</ng-template>

<div class="mt-5" id="addGarmentContainer">
  <h2>Garments</h2>
  <div
    class="addGarmentContainer"
    [ngClass]="{
      'list-bottom-border':
        (_garmentSelectionService.selectedGarments$ | async).length === 0
    }"
  >
    <div
      class="back-drop"
      *ngIf="showRecenltyUsedDialog"
      (click)="showRecenltyUsedDialog = false"
    ></div>

    <ng-container
      *ngIf="(_garmentSelectionService.selectedGarments$ | async).length === 0"
      [ngTemplateOutlet]="recentlyUsedGarments"
    ></ng-container>
    <app-add-item
      id="garment-select-add-item"
      *ngIf="(_garmentSelectionService.selectedGarments$ | async).length === 0"
      info="Select Garments"
      (addItemClicked)="showRecentlyUsed()"
    ></app-add-item>

    <ng-container
      *ngIf="(_garmentSelectionService.selectedGarments$ | async).length !== 0"
    >
      <div
        class="garment-entry"
        *ngFor="
          let garment of _garmentSelectionService.selectedGarments$ | async
        "
      >
        <app-garment-selection-entry
          [garment]="garment"
          [isOutfit2DMode]="this.productType === 12"
          (deleteClicked)="deleteGarmentEntry(garment)"
          (dressClicked)="dressGarment(garment)"
          (tuckClicked)="tuckGarment(garment, $event[1])"
        >
        </app-garment-selection-entry>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="(_garmentSelectionService.selectedGarments$ | async).length > 0"
    class="row addremove"
  >
    <div class="d-flex flex-row align-items-center child-fix-left">
      <ng-container [ngTemplateOutlet]="recentlyUsedGarments"></ng-container>
      <div
        class="d-flex flex-row align-items-center"
        role="button"
        (click)="showRecentlyUsed()"
      >
        <div class="p2 mt-3 mb-3 rr-plus"></div>
        <div class="p2 ml-3 mt-3 mb-3">Add Garments</div>
      </div>
    </div>
    <div
      class="d-flex flex-row ml-auto align-items-center"
      role="button"
      (click)="deleteAllGarments()"
    >
      <div class="p2 mt-3 mb-3 rr-delete"></div>
      <div class="p2 ml-3 mt-3 mb-3">Remove all</div>
    </div>
  </div>
</div>
