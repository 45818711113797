import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { StaticAsset } from '@reactivereality/pictofit-web-sdk';
import { IAppState } from 'projects/content-service-cms/src/app/app.state';

import { IAuthService } from 'projects/content-service-cms/src/app/auth';
import { AddAvatarModalComponent } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/add-avatar-modal/add-avatar-modal.component';
import { AvatarSelectionEntryComponent } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/avatar-selection-entry.component';
import { AvatarSelectionService } from 'projects/content-service-cms/src/app/components/avatar-selection-entry/avatar-selection.service';
import { FileUploadFullComponent } from 'projects/content-service-cms/src/app/components/file-upload-full/file-upload-full.component';
import { AddGarmentModalComponent } from 'projects/content-service-cms/src/app/components/garment-selection/add-garment-modal/add-garment-modal.component';
import { GarmentSelectionComponent } from 'projects/content-service-cms/src/app/components/garment-selection/garment-selection.component';
import { AlertService } from 'projects/content-service-cms/src/app/core/services/alert.service';
import { NgDiscoveryService } from 'projects/content-service-cms/src/app/core/services/ng-discovery.service';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { ViewerMode } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/api';
import { Avatar2DAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/model/Avatar2DAsset';
import { Garment2DAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/data/model/GarmentAssets';
import { IIDAsset } from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/assets';
import { RRIDressingRoomSettings } from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/dressing-room-settings';
import {
  IVDRDisplay,
  IVDRStatus,
} from 'projects/content-service-cms/src/app/virtual-dressing-room/interfaces/vdressing-room';
import { VirtualDressingRoomModule } from 'projects/content-service-cms/src/app/virtual-dressing-room/virtual-dressing-room.module';
import { RRSpinnerModule } from 'projects/web-ui-component-library/src';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { createNewRequestAction } from '../../../store/requests-overview.actions';
import { ActionButtonsComponent } from '../action-buttons/action-buttons.component';
import { CommonInputsComponent } from '../common-inputs/common-inputs.component';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { CreateOutfit2DService } from '../services/create-outfit-2d.service';
import { VirtualDressingRoomComponent } from 'projects/content-service-cms/src/app/virtual-dressing-room/virtual-dressing-room/virtual-dressing-room.component';
import { IScreenShotService } from 'projects/content-service-cms/src/app/core/interfaces/i-screenshot';

@Component({
  selector: 'app-create-outfit-2d',
  templateUrl: './create-outfit-2d.component.html',
  styleUrls: ['./create-outfit-2d.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AddAvatarModalComponent,
    AddGarmentModalComponent,
    RRSpinnerModule,
    VirtualDressingRoomModule,
    FileUploadFullComponent,
    CommonInputsComponent,
    AvatarSelectionEntryComponent,
    GarmentSelectionComponent,
    ActionButtonsComponent,
  ],
})
export class CreateOutfit2DComponent
  implements OnInit, OnDestroy, AfterViewInit, IScreenShotService
{
  public dressingRoomSettings: RRIDressingRoomSettings;
  public vdrUpdate$: BehaviorSubject<IVDRDisplay>;
  public vdrStatus$: BehaviorSubject<IVDRStatus>;
  private _onDestroy$: Subject<void>;
  public vdrReset$: Subject<void>;
  public token = this._authService.getToken();
  public viewerReloadRequired: boolean;

  @ViewChild('MainVDR') vdr: VirtualDressingRoomComponent;

  constructor(
    public customerService: ICustomerService,
    public createRequestModalStore: CreateRequestModalStore,
    private _logger: ILogger,
    private _authService: IAuthService,
    private _discoveryService: NgDiscoveryService,
    private _http: HttpClient,
    private _outfit2DService: CreateOutfit2DService,
    private alertService: AlertService,
    private avatarSelectionService: AvatarSelectionService,
    private _store: Store<IAppState>,
  ) {
    this.vdrStatus$ = new BehaviorSubject<IVDRStatus>(undefined);
    this.vdrUpdate$ = new BehaviorSubject<IVDRDisplay>(undefined);
    this.vdrReset$ = new Subject<void>();
    this.dressingRoomSettings = {
      type: '2D',
      computeServerUrl:
        this._discoveryService.config.apiConfiguration.computeServerUrl,
      height: '100%',
      width: '100%',
      disableAvatarSelection: true,
      disableFullscreenMode: true,
      padding: 0.2,
    };
  }

  async takeScreenshot(height?: number, width?: number): Promise<string> {
    return await this.vdr.takeScreenshot(height, width);
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this._onDestroy$ = new Subject<void>();
    this.vdrStatus$.subscribe((status) => {
      if (status?.error) {
        this._logger.error(`Received error status from VDR!!`);
        this.alertService.showErrorAlert(`Failed to perform 3D tryon.`);
        this.viewerReloadRequired = true;
      } else {
        this._logger.debug(`Received status from VDR!!`, status);
      }
    });
    this.createRequestModalStore.requestCommonDataName$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.isInputValid();
      });
    this.createRequestModalStore.garments$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((_) => {
        this._logger.debug(
          'Garments changed!!',
          this.createRequestModalStore.current(),
        );
        this.viewerReloadRequired = true;
      });
    this.createRequestModalStore.tuckedInGarment$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((_) => {
        this._logger.debug(
          'Tuck changed!!',
          this.createRequestModalStore.current(),
        );
        this.viewerReloadRequired = true;
      });
    this.createRequestModalStore.refreshRoom$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((refresh) => {
        if (refresh) {
          this.applyChangedState();
          this.createRequestModalStore.refreshRoomNow(false);
        }
      });

    this.createRequestModalStore.avatar$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((avatar) => {
        this._logger.debug(`Updated avatar!`, avatar);
        this.updateViewer();
      });

    this.createRequestModalStore.createRequestAction$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.vdr.takeScreenshot();
        await this._outfit2DService.createRequest(
          this.createRequestModalStore,
          image,
          false,
        );
        this.createRequestModalStore.cancelAction();
      });

    this.createRequestModalStore.createRequestActionAndAnotherOne$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.vdr.takeScreenshot();
        await this._outfit2DService.createRequest(
          this.createRequestModalStore,
          image,
          false,
        );
        const state = this.createRequestModalStore.current();
        this._store.dispatch(
          createNewRequestAction({
            productType: state.productType,
          }),
        );
        this.vdrReset$.next();
      });
    this.createRequestModalStore.saveDraftAction$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((c) => c),
      )
      .subscribe(async () => {
        const image = await this.vdr.takeScreenshot();
        await this._outfit2DService.createRequest(
          this.createRequestModalStore,
          image,
          true,
        );
        this.createRequestModalStore.cancelAction();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }

  public addAvatarClicked() {
    this.avatarSelectionService.selectAvatar();
  }
  private resetViewer = () => {
    const currentAvatar = this.createRequestModalStore.currentAvatar();
  };
  private updateViewer = () => {
    const currentAvatar = this.createRequestModalStore.currentAvatar();
    if (!currentAvatar) {
      this._logger.debug('not ready to show');
      return;
    }

    const viewerMode: ViewerMode = ViewerMode.MODE_2D;
    const sceneAsset: IIDAsset = {
      asset: new StaticAsset('/assets/viewer/scenes/2D/default'),
      id: 'test',
    };
    const avatarWebResult = currentAvatar.results.find(
      (r) => r.format === 'web',
    );

    if (!avatarWebResult) {
      this._logger.error(`Failed to load web result of avatar!`);
      throw new Error(`Failed to load web result of avatar!`);
    }

    const avatar: any = new Avatar2DAsset(
      avatarWebResult.files.map((f) => ({
        filename: f.name,
        mimetype: f.mimeType,
        url: f.url,
      })),
      this._http,
      this._logger,
    );

    const currentGarments = this.createRequestModalStore
      .current()
      .dressingRoom.garments.filter(
        (c) => c.results.find((r) => r.format === 'web') !== undefined,
      )
      .map((r) => {
        const result = r.results.find((r) => r.format === 'web');
        return {
          asset: new Garment2DAsset(
            result.files.map((f) => ({
              filename: f.name,
              url: f.url,
            })),
            this._http,
            this._logger,
          ),
          id: r.id,
        };
      });

    this.vdrUpdate$.next({
      garments: currentGarments,
      scene: sceneAsset,
      avatar: {
        id: currentAvatar.id,
        asset: avatar,
      },
      focusedGarment: null,
      sizeRecomendation: null,
      mode: viewerMode,
    });

    this.viewerReloadRequired = false;
    this.isInputValid();
  };

  applyChangedState() {
    this.updateViewer();
  }
  private isInputValid() {
    const currentState = this.createRequestModalStore.current();

    if (
      currentState.requestCommonData.name &&
      currentState.dressingRoom?.avatar &&
      currentState.dressingRoom?.garments?.length > 0
    ) {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: true,
      });
    } else {
      this.createRequestModalStore.changeCreateRequestCommonData({
        dataValid: false,
      });
    }
  }
}
