import {
  AddApiKeyResponseDto,
  ApiKeyDto,
  Configuration,
  ConfigurationBindingModel,
  ConfigurationDTO,
  ConfigurationHasTagKeyDTO,
  ConfigurationPublicOnlyDTO,
  ConfigurationResultDTO,
  ConfigurationResultFileDTO,
  ConfigurationsApi,
  CustomersApi,
  FilesApi,
  ImageDTO,
  ImageDTOPage,
  MIMETypeDTO,
  ProcessApi,
  ProductTypeDTO,
  ProductsApi,
  TagKeyBindingModel,
  TagKeyDTO,
  TagsApi,
  UsersApi,
} from '@reactivereality/cs-api-sdk';
import {
  RRIBatchOption,
  RRIDownloadBatchOptionSettings,
  RRIFilterOutput,
  RRIQueryParams,
  RRITableData,
} from 'projects/web-ui-component-library/src';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FileRevision } from '../core/models/files/file-revision.model';
import { File } from '../core/models/files/file.model';
import { DataService } from '../core/services/data.service';
import { PRODUCT_STATE } from './enums/product-state.enum';
import { PRODUCT_TYPE } from './enums/product-type.enum';
import { REQUEST_STATE_INTERNAL_ID } from './enums/request-state-internal.enum';
import { v4 as uuid } from 'uuid';
import {
  ExtendedCollection,
  ICollection,
} from '../collection-overview/collection.interface';
import {
  AddExternalReferenceToSettingMutation,
  UpdateCollectionMutation,
} from './central-asset-platform-sdk/src/merchant-sdk';

export class IAppConfiguration {
  config: string;
  clientId: string;
  apiVersion: string;
  responseType: string;
  sentry?: {
    dsn: string;
    env: string;
    sample_rate?: number;
  };
}

export class IApiConfiguration {
  name: string;
  production: boolean;
  baseUrl: string;
  baseApiUrl: string;
  authRequestAudience: string;
  authRequestScopes: string;
  baseAuthServiceUrl: string;
  baseViewerUrl: string;
  computeServerUrl: string;
  signInCallback: string;
  signOutCallback: string;
  platformApiBaseUrl: string;
}

export interface IDiscoveryConfiguration {
  appConfiguration: IAppConfiguration;
  apiConfiguration: IApiConfiguration;
}

export interface ICsApiSdkConfiguration {
  apiVersion: string;
  configuration: Configuration;
  customersApiSdk: CustomersApi;
  productApiSdk: ProductsApi;
  processApiSdk: ProcessApi;
  usersApiSdk: UsersApi;
  configurationsApiSdk: ConfigurationsApi;
  filesApiSdk: FilesApi;
  tagsApiSdk: TagsApi;
}

export abstract class IApiSdkService {
  public readonly sdkConfig$: Observable<ICsApiSdkConfiguration | undefined>;
}

export interface ICustomer {
  id: string;
  name: string;
}

export abstract class ICustomerService {
  abstract fetchCustomerById(
    customerId: string,
  ): Promise<ICustomer | undefined>;
}

export interface IProduct {
  id: string;
  customerId: string;
  customerName: string;
  name: string;
  typeId: PRODUCT_TYPE;
  sku: string;
  metadata: { [key: string]: any };
  createdAt: number;
  dueAt: number;
  modifiedAt: number;
  stateId: PRODUCT_STATE;
}

export interface IProductType {
  id: PRODUCT_TYPE;
  name: string;
  reviewEnabled?: boolean;
}

export abstract class IProductService {
  abstract fetchProductByID(productId: string): Promise<IProduct | undefined>;
  abstract updateProduct(product: IProduct): Promise<IProduct | undefined>;
  abstract fetchProductTypes(): Promise<Array<IProductType> | undefined>;
}

export interface IServiceRejectionReason {
  key: string;
  description: string;
}

export abstract class IProcessService {
  abstract readonly rejectionReasonsGarment2D$: Observable<
    Array<IServiceRejectionReason>
  >;
  abstract readonly rejectionReasonsGarment3D$: Observable<
    Array<IServiceRejectionReason>
  >;
  abstract readonly rejectionReasonsOutfit2D$: Observable<
    Array<IServiceRejectionReason>
  >;
  abstract readonly rejectionReasonsAccessory3D$: Observable<
    Array<IServiceRejectionReason>
  >;

  abstract refreshRejectionReasonsGarment2D: () => void;
  abstract refreshRejectionReasonsGarment3D: () => void;
  abstract refreshRejectionReasonsOutfit2D: () => void;
  abstract refreshRejectionReasonsAccessory3D: () => void;
}

export abstract class ITagService {
  abstract createOrUpdateTag(
    id: string,
    tag: TagKeyBindingModel,
  ): Promise<void>;
  abstract getAllTags(): Promise<TagKeyDTO[]>;
  abstract getAllTagsByCustomerId(customerId: string): Promise<TagKeyDTO[]>;
  abstract getTag(id: uuid): Promise<TagKeyDTO>;
  abstract getTagOnlyForCustomer(
    id: uuid,
    customerId: uuid,
  ): Promise<TagKeyDTO>;

  abstract deleteTag(id: uuid): Promise<TagKeyDTO>;
  abstract deleteTagFromConfiguration(
    id: uuid,
    configurationId: uuid,
  ): Promise<ConfigurationHasTagKeyDTO>;
  abstract addTagToConfiguration(
    id: uuid,
    configurationId: uuid,
  ): Promise<ConfigurationHasTagKeyDTO>;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: number;
  modifiedAt: number;
  name?: string;
  featureFlags?: string[];
}

export abstract class IUserService {
  abstract readonly me: Observable<IUser>;

  abstract refreshMeUser: () => void;
  abstract canUserApproveRequest: () => Promise<boolean>;
  abstract canUserRejectRequest: () => Promise<boolean>;

  abstract updateMe: (me: Partial<IUser>) => Promise<void>;

  abstract listApiKeys: () => Promise<Array<ApiKeyDto>>;
  abstract newApiKey: (name?: string) => Promise<AddApiKeyResponseDto>;
  abstract revokeApiKey: (id: string) => Promise<void>;
}

export interface IRequestUpdate extends ConfigurationBindingModel {
  id: string;
}

export interface IRequestDetail {
  id: string;
  name: string;
  customerId?: string;
  productId?: string;
  productTypeId?: PRODUCT_TYPE;
  createdAt?: number;
  dueAt?: number;
  modifiedAt?: number;
  currentStateLogEntryId?: number;
  reviewEnabled?: boolean;
  thumbnailImageID?: string;
  previewUrl?: string;
  metadata?: { [key: string]: any };
  priority?: string;
  results?: IRequestResult[];
  sku?: string;
  stateId?: REQUEST_STATE_INTERNAL_ID;
  stateMetadata?: { [key: string]: any };
  url?: string;
  tags?: any[];
  storageInTransit?: boolean;
  storageLocation?: string;
}

export interface IRequestHistoryState {
  configurationId: string;
  state: REQUEST_STATE_INTERNAL_ID;
  entryId: number;
  metadata: { [key: string]: any };
  createdAt: number;
  user: IUser;
}

export interface IRequestPrioritiy {
  id: string;
  name: string;
}

export interface IRequestState {
  id: number;
  name: string;
}

export interface IRequestResult {
  id: string;
  format: string;
  configurationId?: string;
  createdAt?: number;
  createdBy?: string;
  modifiedBy?: string;
  modifiedAt?: number;
  files?: IRequestResultFile[];
}

export interface IRequestResultFile {
  id: string;
  name: string;
  configurationResultId?: string;
  url?: string;
  createdAt?: number;
  createdBy?: string;
  modifiedAt?: number;
  modifiedBy?: string;
  typeId?: number;
  mimeType?: string;
}

export interface IRequest {
  id: string;
  name: string;
  createdAt?: number;
  currentStateLogEntryId?: number;
  customerId?: string;
  customerName?: string;
  dueAt?: number;
  metadata?: {};
  modifiedAt?: number;
  productId?: string;
  productTypeId?: string;
  results?: IRequestResult[];
  sku?: string;
  stateCreatedAt?: number;
  stateCreatedBy?: string;
  stateCreatedByFirstName?: string;
  stateCreatedByLastName?: string;
  stateMetadata?: { reason?: string; additionalInformation?: string };
  url?: string;
  priority?: string;
  reviewEnabled?: boolean;
  stateId?: REQUEST_STATE_INTERNAL_ID;
  ThumbnailImageID?: string;
  previewUrl?: string;
  tags: { tagKeyColor: string; tagKeyName: string; value: string | null }[];
}

export interface IResquestStateCount {
  stateId: REQUEST_STATE_INTERNAL_ID;
  count: number;
}

export interface IRequestsAggregate {
  statesCount: IResquestStateCount[];
}

export abstract class IRequestService extends DataService<IRequest, any> {
  abstract allPriorities_loading$: BehaviorSubject<boolean>;
  abstract readonly allPriorities$: BehaviorSubject<IRequestPrioritiy[]>;

  abstract getRequestByID(requestId: string): Promise<ConfigurationDTO>;
  abstract getRequests(
    filter: string,
    page: number,
    pageSize: number,
    includeResult?: boolean,
  ): Promise<PagingRequestDTO>;
  abstract getPublicRequests(
    filter: string,
    page: number,
    pageSize: number,
  ): Promise<PagingPublicRequestDTO>;
  abstract update(request: IRequestUpdate): Promise<IRequestDetail>;
  abstract delete(request: IRequestDetail): Promise<IRequestDetail>;

  abstract getResponseFromUrl(url: string): Promise<Response>;

  abstract approveRequest(requestId: string): Promise<void>;
  abstract rejectRequest(
    request: IRequestDetail,
    metadata: { [key: string]: any },
  ): Promise<void>;
  abstract setReady(id: string): Promise<void>;

  abstract getHistory(requestId: string): Promise<IRequestHistoryState[]>;

  abstract getResult(requestResultId: string): Promise<IRequestResult>;
  abstract getResults(requestId: string): Promise<IRequestResult[]>;

  abstract getData(
    params: RRIQueryParams<IRequest, any>,
  ): Observable<RRITableData<IRequest>>;
  abstract getRequestAggregates(
    filters?: RRIFilterOutput<any>[],
    tags?: RRIQueryParams<IRequest, any>['tags'],
  ): Observable<IRequestsAggregate>;
  abstract getAllCustomerRequests(
    customerId: string,
    filter: string,
    page: number,
    pageSize: number,
    tags: string,
    inlcudedArchived?: boolean,
    cancelSignal?: AbortSignal,
  ): Promise<PagingRequestDTO>;
}

export interface IFileInformation {
  id: string;
  configurationId: string;
  fileName: string;
  typeId: number;
  mimeType: string;
}

export interface IFileUpload {
  name: string;
  configurationId: string;
  mimeType: string;
  type: string;
  ensureUniqueness?: boolean;
}

export interface IFileUploadResponse {
  fileId: string;
  uploadUrl: string;
  acknowledgeUrl: string;
}

export interface IResultFileUpload {
  name: string;
  mimeType: string;
}

export abstract class IFileUploadService {
  abstract uploadJsonFile(
    fileInfo: IFileInformation,
    jsonObject: object,
  ): Promise<void>;
  abstract uploadBase64File(
    fileInfo: IFileInformation,
    base64Data: string,
  ): Promise<void>;
  abstract uploadFile(
    fileInfo: IFileInformation,
    fileBlob: Blob,
  ): Promise<ConfigurationResultFileDTO>;
}

export abstract class IPublicRequestService extends DataService<IRequest, any> {
  abstract getPublicRequestByID(requestId: string): Promise<IRequestDetail>;
  abstract getFirstApprovedAvatar2D(): any;
}

export abstract class IGarmentService extends DataService<IRequestDetail, any> {
  abstract getMetadata<T>(webResult: IRequestResult): Promise<T | undefined>;
  abstract mapRequestsToGarments(
    request: IRequestDetail,
    additional?: {
      isPublic?: boolean;
      isSelected?: boolean;
      dressed?: boolean;
      tuckedIn?: boolean;
    },
  ): Promise<GarmentDTO>;
}

export abstract class IFileDownloadService<T> extends DataService<File, any> {
  abstract getFile(fileId: string): Observable<File>;
  abstract getFileExtension(
    file: File | IRequestResultFile | FileRevision,
  ): string;
  abstract fileDownload(
    entities: any[],
    visibleBatchOptions: RRIBatchOption<RRIDownloadBatchOptionSettings<T, any>>,
    singleDownload: boolean,
  ): void;
  abstract downloadFile(file: File): Observable<any>;
  abstract downloadResultFile(file: IRequestResultFile): Observable<any>;
  abstract downloadZippedFiles(
    results: ConfigurationResultDTO[],
    name: string,
  ): Promise<void>;
  abstract downloadFiles(results: ConfigurationResultDTO[]): Promise<void>;
}

export abstract class IFileService<T> extends DataService<File, any> {
  abstract isLoading: Subject<any>;
  abstract fileDownload(
    entities: any[],
    visibleBatchOptions: RRIBatchOption<RRIDownloadBatchOptionSettings<T, any>>,
    singleDownload?: boolean,
  ): void;
  abstract downloadFile(file: File): any;
  abstract loadFile(lbActiveFile: File): Observable<any>;
  abstract setFileConfiguration(file: File): void;
  abstract getRequestFiles(
    requestId: string,
    filters?: string,
  ): Promise<ImageDTOPage>;
  abstract getFilesFromRequest(): Promise<ImageDTOPage>;
  abstract deleteFile(file: ImageDTO): Promise<ImageDTO>;
  abstract getTypes(): Promise<ProductTypeDTO[]>;
  abstract getFile(fileId: string): Promise<File>;
  abstract getMimeTypes(): Promise<MIMETypeDTO[]>;
}

export interface IPriority {
  id: string;
  name: string;
}

export interface AvatarDTO extends ConfigurationDTO {
  isPublic: boolean;
  enabled?: boolean;
  archived?: boolean;
}
export interface GarmentDTO extends ConfigurationDTO {
  groupId: number;
  isPublic: boolean;
  selected: boolean;
  tuckedIn: boolean;
  dressed: boolean;
  tryonable: boolean;
  archived?: boolean;
}

export interface PagingData {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  recordCount: number;
}

export interface PagingRequestDTO {
  configurations: ConfigurationDTO[];
  paging?: PagingData;
}

export interface PagingPublicRequestDTO {
  configurations: ConfigurationPublicOnlyDTO[];
  paging?: PagingData;
}

export abstract class ICentralAssetPlatformService {
  abstract getAllCollections(org: uuid): Promise<ExtendedCollection[]>;
  abstract createCollection(org: uuid, collection: ICollection): Promise<uuid>;
  abstract referenceExists(org: uuid, reference: string): Promise<boolean>;
  // abstract getCollection(
  //   org: uuid,
  //   ref: string,
  // ): Promise<{ id: string; collection: ICollection }>;
  abstract updateCollection(
    id: uuid,
    collection: ICollection,
  ): Promise<UpdateCollectionMutation>;
  abstract addExternalReferenceToSetting(
    setting: string,
    eference: string,
  ): Promise<AddExternalReferenceToSettingMutation>;
  abstract getThumbnail(org: uuid, ref: string): Promise<{ thumbnail: string }>;
  abstract getId(org: uuid, ref: string): Promise<{ id: uuid }>;
}

export abstract class ISimpleFileService {
  abstract readJsonFile(url: string): Observable<any>;
}
