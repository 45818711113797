import { Component } from '@angular/core';
import { GarmentReference } from '../../collection-screen.state';
import { CommonModule } from '@angular/common';
import { BaseListComponent } from '../base-list/base-list.component';
import { GarmentListRowComponent } from '../garment-list-row/garment-list-row.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-garment-list',
  standalone: true,
  imports: [
    GarmentListComponent,
    GarmentListRowComponent,
    CommonModule,
    DragDropModule,
  ],
  templateUrl: './garment-list.component.html',
  styleUrl: '../base-list/base-list.scss',
})
export class GarmentListComponent extends BaseListComponent<GarmentReference> {
  constructor() {
    super();
  }
}
