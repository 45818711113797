import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RRSpinnerModule } from 'projects/web-ui-component-library/src';
import { IRequestService, PRODUCT_TYPE } from '../../../data';
import { Subject, takeUntil } from 'rxjs';
import {
  CreateRequestInputData,
  UpdateRequestInputData,
} from './create-request-input.interface';
import { ILogger } from '../../../logging';
import { IAppState } from '../../../app.state';
import { Store } from '@ngrx/store';
import { selectRequestsModalFilter } from '../../store/requests-overview.selectors';
import { reloadRequestsAction } from '../../store/requests-overview.actions';
import { CreateRequestModalStore } from './create-request-modal.state';
import { CreateRequestService } from './services/create-request.service';
import { IGarmentSelectionService } from '../../../components/garment-selection/services/i-garment-selection.service';
import { CreateRequestGarmentSelectionService } from './services/create-garment-selection.service';
import { CommonModule } from '@angular/common';
import { CreateGarment2DComponent } from './create-garment-2d/create-garment-2d.component';
import { CreateGarment3DCADComponent } from './create-garment-3d-cad/create-garment-3d-cad.component';
import { CreateOutfit2DComponent } from './create-outfit-2d/create-outfit-2d.component';
import { CreateOutfit3DComponent } from './create-outfit-3d/create-outfit-3d.component';
import { AddGarmentModalComponent } from '../../../components/garment-selection/add-garment-modal/add-garment-modal.component';
import { AddAvatarModalComponent } from '../../../components/avatar-selection-entry/add-avatar-modal/add-avatar-modal.component';
import { ModalService } from '../../../modal/modal.service';
import { AvatarSelectionService } from '../../../components/avatar-selection-entry/avatar-selection.service';

@Component({
  selector: 'app-create-request-modal',
  templateUrl: './create-request-modal.component.html',
  styleUrls: ['./create-request-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CreateGarment2DComponent,
    CreateGarment3DCADComponent,
    CreateOutfit2DComponent,
    CreateOutfit3DComponent,
    RRSpinnerModule,
    AddGarmentModalComponent,
    AddAvatarModalComponent,
  ],
  providers: [
    CreateRequestModalStore,
    AvatarSelectionService,
    {
      provide: IGarmentSelectionService,
      useClass: CreateRequestGarmentSelectionService,
    },
  ],
})
export class CreateRequestModalComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void>;
  public allProductTypes = PRODUCT_TYPE;

  @ViewChild('createRequestModalBody') createRequestModalBody;

  constructor(
    private _requestService: IRequestService,
    private _logger: ILogger,
    private store: Store<IAppState>,
    public createRequestModalStore: CreateRequestModalStore,
    private createRequestService: CreateRequestService,
    public modalService: ModalService,
  ) {}

  async ngOnInit(): Promise<void> {
    this._onDestroy$ = new Subject<void>();

    this._requestService.allPriorities_loading$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._logger.debug('loading'));

    this.createRequestModalStore.inputData$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        (v: CreateRequestInputData | UpdateRequestInputData | null) => {
          this._logger.debug(`Input data is set in store!`, v);
          if (v !== null) {
            this.createRequestService.prepare(v, this.createRequestModalStore);
          }
        },
      );

    this.store
      .select(selectRequestsModalFilter)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((v) => {
        this.createRequestModalStore.reset();

        if (v === null) {
          this.closeModal();
          return;
        }

        this.modalService.switch({
          title: 'Create New Request',
          template: this.createRequestModalBody,
          confirmDiscard: true,
          onClose: () => {
            this._logger.debug('Loading requests...');
            this.store.dispatch(reloadRequestsAction());
          },
        });

        this.createRequestModalStore.setInputData(v);
      });

    this.createRequestModalStore.cancelAction$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((c) => {
        if (c) {
          this.closeModal();
        }
      });
  }

  public closeModal() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onDestroy$.unsubscribe();
    this._onDestroy$ = undefined;
  }
}
