<h2>
  {{ title }}
  <div
    class="loader"
    title="loading..."
    [hidden]="(loading$ | async) === false"
  ></div>
  <button
    class="btn btn-primary"
    (click)="openClicked()"
    [hidden]="loading$ | async"
  >
    Edit
  </button>
</h2>
<div
  cdkDropList
  [hidden]="!visible"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListAutoScrollStep]="20"
  [cdkDropListAutoScrollDisabled]="false"
>
  <span *ngIf="references?.length === 0"><b>No items selected...</b></span>
  <div *ngFor="let reference of references; trackBy: identify" cdkDrag>
    <app-outfit-list-row
      [reference]="reference"
      (removeReference)="this.removeReference($event)"
      (moveReferenceUp)="this.moveRefUp.emit($event)"
      (moveReferenceDown)="this.moveRefDown.emit($event)"
    >
    </app-outfit-list-row>
  </div>
</div>
