<app-asset-list
  title="Avatars"
  [loading$]="this.createCollectionStore.avatarsLoading$"
  (addOrUpdateReference)="this.createCollectionStore.addOrUpdateAvatar($event)"
  (removeRef)="this.createCollectionStore.removeAvatar($event)"
  (moveRefUp)="
    this.createCollectionStore.moveAvatar({ id: $event.id, direction: 'up' })
  "
  (moveRefDown)="
    this.createCollectionStore.moveAvatar({ id: $event.id, direction: 'down' })
  "
  (updateVisibility)="this.createCollectionStore.setSearchProductType(6)"
  [data$]="this.createCollectionStore.avatars$"
  [visible]="this.createCollectionStore.avatarsVisible$ | async"
  class="mb-3"
>
</app-asset-list>
<app-asset-list
  title="Scenes"
  [loading$]="this.createCollectionStore.scenesLoading$"
  (click)="this.createCollectionStore.setSearchProductType(14)"
  [data$]="createCollectionStore.scenes$"
  (addOrUpdateReference)="this.createCollectionStore.addOrUpdateScene($event)"
  (removeRef)="this.createCollectionStore.removeScene($event)"
  (moveRefUp)="
    this.createCollectionStore.moveScene({ id: $event.id, direction: 'up' })
  "
  (moveRefDown)="
    this.createCollectionStore.moveScene({ id: $event.id, direction: 'down' })
  "
  [visible]="this.createCollectionStore.scenesVisible$ | async"
  class="mb-3"
>
</app-asset-list>
<app-garment-list
  title="Garments"
  [loading$]="this.createCollectionStore.garmentsLoading$"
  (click)="this.createCollectionStore.setSearchProductType(1)"
  [data$]="createCollectionStore.garments$"
  (addOrUpdateReference)="this.createCollectionStore.addOrUpdateGarment($event)"
  (removeRef)="this.createCollectionStore.removeGarment($event)"
  (moveRefUp)="
    this.createCollectionStore.moveGarment({ id: $event.id, direction: 'up' })
  "
  (moveRefDown)="
    this.createCollectionStore.moveGarment({ id: $event.id, direction: 'down' })
  "
  [visible]="this.createCollectionStore.garmentsVisible$ | async"
  class="mb-3"
>
</app-garment-list>
<app-outfit-list
  title="Outfits"
  [loading$]="this.createCollectionStore.outfitsLoading$"
  (click)="this.createCollectionStore.setSearchProductType(21)"
  [data$]="createCollectionStore.outfits$"
  (addOrUpdateReference)="this.createCollectionStore.addOrUpdateOutfit($event)"
  (removeRef)="this.createCollectionStore.removeOutfit($event)"
  (moveRefUp)="
    this.createCollectionStore.moveOutfit({ id: $event.id, direction: 'up' })
  "
  (moveRefDown)="
    this.createCollectionStore.moveOutfit({ id: $event.id, direction: 'down' })
  "
  [visible]="this.createCollectionStore.outfitsVisible$ | async"
  class="mb-3"
>
</app-outfit-list>

<button
  class="btn btn-primary"
  (click)="createCollection()"
  [disabled]="createCollectionStore.loading$ | async"
>
  {{
    (createCollectionStore.screenMode$ | async) === 'new'
      ? 'Create Collection'
      : 'Update Collection'
  }}
</button>

<a href (click)="downloadCollection($event)" class="mt-3">
  {{ (createCollectionStore.loading$ | async) ? '' : 'View JSON' }}
</a>

<a href (click)="previewCollection($event)" class="mt-3">
  {{ (createCollectionStore.loading$ | async) ? '' : 'Preview' }}
</a>
