import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { createSelector } from '@ngrx/store';
import { TagKeyDTO } from '@reactivereality/cs-api-sdk';
import * as _ from 'lodash';
import { RRSpinnerModel } from 'projects/web-ui-component-library/src';
import { IRequestDetail, PRODUCT_TYPE } from '../../../data';
import { AvatarDTO, GarmentDTO } from '../../../data/api';
import {
  CloneRequestInputData,
  CreateRequestCommonData,
  CreateRequestInputData,
  UpdateRequestInputData,
} from './create-request-input.interface';

export interface CreateRequestModalState {
  requestCommonData?: CreateRequestCommonData;
  existingRequest: IRequestDetail | null;
  tags: Array<TagKeyDTO>;
  loadingState: RRSpinnerModel;
  skipManualStep: boolean;
  productType: PRODUCT_TYPE;
  inputData:
    | CreateRequestInputData
    | UpdateRequestInputData
    | CloneRequestInputData
    | null;
  features: {
    supportDraft: boolean;
    supportDueDate: boolean;
    supportPriority: boolean;
    supportSize: boolean;
  };
  dressingRoom: {
    avatar: AvatarDTO | null;
    garments: Array<
      GarmentDTO & {
        stylingOptions?: any;
      }
    >;
    selectedGarments: Array<
      GarmentDTO & {
        stylingOptions?: any;
      }
    >;
    tuckedInGarment: GarmentDTO & {
      stylingOptions?: any;
    };
    refreshDressingRoom: boolean;
  };
  actions: {
    cancel: boolean;
    cancelAndRedirect: string[];
    createRequest: boolean;
    saveDraft: boolean;
    createRequestAndAnotherOne: boolean;
    startRequest: boolean;
  };
}

const initState: CreateRequestModalState = {
  requestCommonData: {
    dataValid: false,
  },
  existingRequest: null,
  tags: [],
  inputData: null,
  loadingState: {
    show: false,
    text: 'Loading...',
    id: 'create-request',
  },
  skipManualStep: false,
  productType: PRODUCT_TYPE.UNDEFINED,
  features: {
    supportDraft: false,
    supportDueDate: false,
    supportPriority: false,
    supportSize: false,
  },
  dressingRoom: {
    avatar: null,
    garments: [],
    selectedGarments: [],
    refreshDressingRoom: false,
    tuckedInGarment: null,
  },
  actions: {
    cancel: false,
    cancelAndRedirect: [],
    createRequest: false,
    saveDraft: false,
    createRequestAndAnotherOne: false,
    startRequest: false,
  },
};

@Injectable()
export class CreateRequestModalStore extends ComponentStore<CreateRequestModalState> {
  constructor() {
    super(initState);
  }

  readonly currentAvatar = () => {
    return this.get().dressingRoom.avatar;
  };
  readonly currentCommonData = () => {
    return this.get().requestCommonData;
  };
  readonly currentExistingRequest = () => {
    return this.get().existingRequest;
  };
  readonly currentSkipManualStep = () => {
    return this.get().skipManualStep;
  };
  readonly current = () => {
    return this.get();
  };

  readonly loadingState$ = this.select(selectLoadingState);
  readonly requestCommonData$ = this.select(selectCommonData);
  readonly requestCommonDataName$ = this.select(selectCommonDataName);
  readonly requestCommonDataSize$ = this.select(selectCommonDataSize);
  readonly requestCommonDataPriority$ = this.select(selectCommonDataPriority);
  readonly requestCommonDataValid$ = this.select(selectCommonDataIsValid);
  readonly skipManualStep$ = this.select(selectSkipManualStep);

  readonly supportDraft$ = this.select((s) => s.features.supportDraft);
  readonly supportDueDate$ = this.select((s) => s.features.supportDueDate);
  readonly supportPriority$ = this.select((s) => s.features.supportPriority);
  readonly supportSize$ = this.select((s) => s.features.supportSize);
  readonly productType$ = this.select((s) => s.productType);
  readonly inputData$ = this.select((s) => s.inputData);
  readonly existingRequest$ = this.select((s) => s.existingRequest);
  readonly avatar$ = this.select((s) => s.dressingRoom.avatar);
  readonly garments$ = this.select((s) => s.dressingRoom.garments);
  readonly tuckedInGarment$ = this.select(
    (s) => s.dressingRoom.tuckedInGarment,
  );
  readonly selectedGarments$ = this.select(
    (s) => s.dressingRoom.selectedGarments,
  );
  readonly refreshRoom$ = this.select(
    (s) => s.dressingRoom.refreshDressingRoom,
  );
  readonly tags$ = this.select((s) => s.tags);

  readonly reset = this.updater((_) => ({
    ...initState,
  }));

  readonly showLoading = this.updater((state) => ({
    ...state,
    loadingState: {
      ...state.loadingState,
      show: true,
    },
  }));

  readonly hideLoading = this.updater((state) => ({
    ...state,
    loadingState: {
      ...state.loadingState,
      show: false,
    },
  }));

  readonly changeCreateRequestCommonData = this.updater(
    (state, data: Partial<CreateRequestCommonData>) => ({
      ...state,
      requestCommonData: {
        ...state.requestCommonData,
        ...data,
      },
    }),
  );

  readonly clearCreateRequestCommonData = this.updater((state) => ({
    ...state,
    requestCommonData: {
      dataValid: false,
    },
  }));

  readonly changeSkipManualStep = this.updater((state, data: boolean) => ({
    ...state,
    skipManualStep: data,
  }));

  readonly switchSkipManualStep = this.updater((state) => ({
    ...state,
    skipManualStep: !state.skipManualStep,
  }));

  readonly setProductType = this.updater((state, type: PRODUCT_TYPE) => ({
    ...state,
    productType: type,
  }));

  readonly clearExistingRequest = this.updater((state) => ({
    ...state,
    existingRequest: null,
  }));

  readonly setExistingRequest = this.updater((state, req: IRequestDetail) => ({
    ...state,
    existingRequest: req,
  }));

  readonly setFeatures = this.updater(
    (state, features: CreateRequestModalState['features']) => ({
      ...state,
      features,
    }),
  );

  readonly setInputData = this.updater(
    (
      state,
      inputData:
        | CreateRequestInputData
        | UpdateRequestInputData
        | CloneRequestInputData
        | null,
    ) => ({
      ...state,
      inputData,
    }),
  );

  readonly setAvatar = this.updater((state, avatar: AvatarDTO | null) => ({
    ...state,
    dressingRoom: {
      ...state.dressingRoom,
      avatar,
    },
  }));

  readonly setGarments = this.updater(
    (
      state,
      garments: Array<
        GarmentDTO & {
          stylingOptions?: any;
        }
      >,
    ) => ({
      ...state,
      dressingRoom: {
        ...state.dressingRoom,
        garments,
      },
    }),
  );

  readonly refreshRoomNow = this.updater((state, refresh: boolean) => ({
    ...state,
    dressingRoom: {
      ...state.dressingRoom,
      refreshDressingRoom: refresh,
    },
  }));

  readonly setSelectedGarments = this.updater(
    (state, garments: Array<GarmentDTO>) => ({
      ...state,
      dressingRoom: {
        ...state.dressingRoom,
        selectedGarments: garments,
      },
    }),
  );
  readonly setTuckedInGarment = this.updater((state, garment: GarmentDTO) => ({
    ...state,
    dressingRoom: {
      ...state.dressingRoom,
      tuckedInGarment: garment,
    },
  }));
  readonly setTags = this.updater((state, tags: Array<TagKeyDTO>) => ({
    ...state,
    tags,
  }));

  readonly addTagEntry = this.updater((state, tag: TagKeyDTO) => ({
    ...state,
    tags: _.uniqBy([...state.tags, tag], (v) => v.id),
  }));

  readonly clearTagEntry = this.updater((state, tag: TagKeyDTO) => ({
    ...state,
    tags: [],
  }));

  readonly removeTagEntry = this.updater((state, tag: TagKeyDTO) => ({
    ...state,
    tags: [...state.tags, tag].filter((t) => t.id !== tag.id),
  }));

  // Actions

  readonly cancelAction$ = this.select((s) => s.actions.cancel);
  readonly cancelAndRedirectAction$ = this.select(
    (s) => s.actions.cancelAndRedirect,
  );
  readonly createRequestActionAndAnotherOne$ = this.select(
    (s) => s.actions.createRequestAndAnotherOne,
  );
  readonly createRequestAction$ = this.select((s) => s.actions.createRequest);
  readonly saveDraftAction$ = this.select((s) => s.actions.saveDraft);
  readonly startRequestAction$ = this.select((s) => s.actions.startRequest);

  readonly cancelAction = this.updater((state) => ({
    ...state,
    loadingState: {
      ...state.loadingState,
      show: false,
    },
    actions: {
      ...state.actions,
      cancel: true,
      cancelAndRedirect: [],
    },
  }));

  readonly cancelAndRedirectAction = this.updater(
    (state, redirectTo: string[]) => ({
      ...state,
      loadingState: {
        ...state.loadingState,
        show: false,
      },
      actions: {
        ...state.actions,
        cancel: true,
        cancelAndRedirect: redirectTo,
      },
    }),
  );

  readonly createRequestAction = this.updater((state) => ({
    ...state,
    loadingState: {
      ...state.loadingState,
      show: true,
    },
    actions: {
      ...state.actions,
      createRequest: true,
    },
  }));

  readonly createRequestActionAnAnotherOne = this.updater((state) => ({
    ...state,
    loadingState: {
      ...state.loadingState,
      show: true,
    },
    actions: {
      ...state.actions,
      createRequestAndAnotherOne: true,
    },
  }));

  readonly saveDraftAction = this.updater((state) => ({
    ...state,
    loadingState: {
      ...state.loadingState,
      show: true,
    },
    actions: {
      ...state.actions,
      saveDraft: true,
    },
  }));

  readonly startRequestAction = this.updater((state) => ({
    ...state,
    actions: {
      ...state.actions,
      startRequest: true,
    },
  }));

  readonly resetStartRequestAction = this.updater((state) => ({
    ...state,
    actions: {
      ...state.actions,
      startRequest: false,
    },
  }));

  readonly setGarmentStylingOptions = this.updater(
    (state, update: { garment: GarmentDTO; options: any }) => {
      const garments = state.dressingRoom.garments.map((garment) => {
        if (garment.id !== update.garment.id) return garment;

        garment.stylingOptions = update.options;
        return garment;
      });

      const selectedGarments = state.dressingRoom.selectedGarments.map(
        (garment) => {
          if (garment.id !== update.garment.id) return garment;

          garment.stylingOptions = update.options;
          return garment;
        },
      );

      return {
        ...state,
        dressingRoom: {
          ...state.dressingRoom,
          selectedGarments,
          garments,
        },
      };
    },
  );
}

// Selectors

export const selectLoadingState = (state: CreateRequestModalState) =>
  state.loadingState;
export const selectLoadingStateShow = createSelector(
  selectLoadingState,
  (state: RRSpinnerModel) => state.show,
);
export const selectLoadingStateText = createSelector(
  selectLoadingState,
  (state: RRSpinnerModel) => state.text,
);

export const selectSkipManualStep = (state: CreateRequestModalState) =>
  state.skipManualStep;

export const selectCommonData = (state: CreateRequestModalState) =>
  state.requestCommonData;

export const selectCommonDataName = createSelector(
  selectCommonData,
  (state: CreateRequestCommonData) => state.name,
);
export const selectCommonDataPriority = createSelector(
  selectCommonData,
  (state: CreateRequestCommonData) => state.priority,
);
export const selectCommonDataSize = createSelector(
  selectCommonData,
  (state: CreateRequestCommonData) => state.size,
);
export const selectCommonDataIsValid = createSelector(
  selectCommonData,
  (state: CreateRequestCommonData) => state.dataValid,
);
