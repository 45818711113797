<div class="d-flex flex-row">
  <img src="{{ reference.thumbnail }}" class="thumbnail mb-3" height="160px" />
  <div class="d-flex flex-column ml-3">
    <app-input-text
      id="ref-name-txt"
      placeholder="Name"
      class="mr-3"
      [value]="reference.name"
      (valueChange)="onNameChanged($event)"
    ></app-input-text>
    <span><b>Reference:</b> {{ reference.reference }}</span>
    <span><b>CS-ID:</b> {{ reference.id }}</span>
  </div>
  <app-row-buttons
    [reference]="reference"
    (moveRowDown)="this.moveReferenceDown.emit(this.reference)"
    (moveRowUp)="this.moveReferenceUp.emit(this.reference)"
    (removeRow)="this.removeReference.emit(this.reference)"
  ></app-row-buttons>
</div>
