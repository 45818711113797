import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { CustomerStorageService } from 'projects/content-service-cms/src/app/customer/service/customer-storage.service';
import { PRODUCT_TYPE } from 'projects/content-service-cms/src/app/data';
import {
  IRequestService,
  PagingRequestDTO,
} from 'projects/content-service-cms/src/app/data/api';
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  from,
  takeUntil,
} from 'rxjs';
import { CollectionScreenStore } from '../../collection-screen.state';
import { CommonModule } from '@angular/common';
import { ReferenceEntryComponent } from './components/reference-entry/reference-entry.component';
import { ConfigurationDTO } from '@reactivereality/cs-api-sdk';

import { InputTextComponent } from '../../../components/inputs/input-text/input-text.component';
import {
  CollectionFilters,
  CollectionSearchFilterBarComponent,
} from './components/collection-search-filter-bar/collection-search-filter-bar.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { IPaginationDataProviderInterface } from '../../../request-overview/services/pagination-data-provider.interface';
import { ComponentPaginationDataProvider } from '../../../request-overview/services/component-pagination-data-provider';
import { RequestListPaginationComponent } from '../../../request-overview/components/request-list-page/request-list-pagination/request-list-pagination.component';
import { SelectionModalStore } from '../../../request-overview/components/create-request-modal/store/selection-modal-store';
import { SelectedFilters } from 'projects/web-ui-component-library/src';

export interface IReferenceSelectionData {
  id: string;
  type: PRODUCT_TYPE;
  name: string;
  reference: string;
  organizationId: string;
  thumbnail?: string;
  additionalDataUrl?: string;
}

@Component({
  selector: 'app-reference-selection',
  standalone: true,
  imports: [
    CommonModule,
    ReferenceEntryComponent,
    InputTextComponent,
    CollectionSearchFilterBarComponent,
    PaginationModule,
    RequestListPaginationComponent,
  ],
  templateUrl: './reference-selection.component.html',
  styleUrl: './reference-selection.component.scss',
  providers: [
    SelectionModalStore,
    {
      provide: IPaginationDataProviderInterface,
      useClass: ComponentPaginationDataProvider,
    },
  ],
})
export class ReferenceSelectionComponent implements AfterViewInit, OnDestroy {
  public requestList: IReferenceSelectionData[] = [];
  private _abortController: AbortController;
  private _currentPage = 1;
  private _onDestroy$: Subject<void>;
  private _selectedType: any;

  constructor(
    public createCollectionStore: CollectionScreenStore,
    private _customerStorageService: CustomerStorageService,
    private _requestService: IRequestService,
    private _store: SelectionModalStore,
  ) {}
  async ngAfterViewInit(): Promise<void> {
    this._onDestroy$ = new Subject<void>();

    combineLatest([
      this.createCollectionStore.searchProductType$,
      this.createCollectionStore.filters$,
      this._store.currentPage$,
    ])
      .pipe(debounceTime(500))
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(async ([type, filters, currentPage]) => {
        if (this._selectedType !== type) {
          this._selectedType = type;
          this._currentPage = 1;
          this._store.updateCurrentPage(1);
        } else {
          this._currentPage = currentPage;
        }
        if (type !== PRODUCT_TYPE.UNDEFINED) {
          this.getRequests([type], this.formatFilters(filters)).subscribe(
            (response) => {
              const mapped = response.configurations.map((c) => {
                return {
                  id: c.id,
                  name: c.name,
                  reference: `gid://pictofit/sku/${c.sku}`,
                  type: c.productTypeId,
                  thumbnail: c.previewUrl,
                  organizationId: c.customerId,
                  additionalDataUrl:
                    type === PRODUCT_TYPE.OUTFIT_2D_PARALLAX
                      ? getOutfitParallaxInfoUrl(c)
                      : undefined,
                } as IReferenceSelectionData;
              });
              this.createCollectionStore.setFilteredCollections(mapped);
              this._store.updatePageCount(response.paging.pageCount);
            },
          );
        }
      });

    this.createCollectionStore.filteredRequests$.subscribe((data) => {
      this.requestList = data;
    });
  }
  formatFilters(filters: SelectedFilters<CollectionFilters>): any {
    const result = {};
    if (filters.name) {
      result['name'] = filters.name;
    }
    if (filters.sku) {
      result['sku'] = filters.sku;
    }
    if (filters.createdAt) {
      const res = [
        filters.createdAt.from.getTime() / 1000,
        filters.createdAt.to.getTime() / 1000,
      ];
      result['createdAt'] = res;
    }
    return result;
  }

  ngOnDestroy(): void {
    if (this._onDestroy$) {
      this._onDestroy$.next();
      this._onDestroy$.complete();
      this._onDestroy$.unsubscribe();
      this._onDestroy$ = undefined;
    }
  }

  private getRequests(
    type: PRODUCT_TYPE[],
    filters,
  ): Observable<PagingRequestDTO> {
    let requestFilter = {
      productTypeId: type,
      stateId: [4, 13],
    };

    requestFilter = { ...requestFilter, ...filters };

    this._abortController = new AbortController();
    return from(
      this._requestService.getAllCustomerRequests(
        this._customerStorageService.getSelectedCustomer(),
        JSON.stringify(requestFilter),
        this._currentPage,
        10,
        undefined,
        true,
        this._abortController.signal,
      ),
    );
  }
}
export function getOutfitParallaxInfoUrl(c: ConfigurationDTO): string {
  //extract the json file from the config.
  const webResult = c.results.filter(
    (o) => o.format.toLocaleLowerCase() === 'web',
  );
  if (webResult.length > 0) {
    const result = webResult[0];
    const json = result.files.find((o) => o.name === 'resolved.json');
    if (json) {
      return json.url;
    } else return undefined;
  }
}
