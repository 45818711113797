<div class="d-flex flex-row mb-5">
  <div class="d-flex flex-column">
    <img
      src="{{ this.createCollectionStore.infoImage$ | async }}"
      height="200px"
    />
  </div>
  <div class="d-flex flex-column ml-3">
    <app-input-text
      id="create-collection-name-txt"
      class="mb-5"
      placeholder="Name"
      [required]="true"
      [value]="this.createCollectionStore.infoName$ | async"
      (valueChange)="this.createCollectionStore.setInfoName($event.value)"
    ></app-input-text>

    <app-input-text
      id="create-collection-imageUrl-txt"
      placeholder="Image URL"
      [required]="true"
      [value]="this.createCollectionStore.infoImage$ | async"
      (valueChange)="this.createCollectionStore.setInfoImageUrl($event.value)"
    ></app-input-text>
  </div>
</div>
