<div class="d-flex flex-row">
  <div>
    <img
      src="{{ reference.thumbnail }}"
      class="thumbnail mb-3"
      style="height: 160px; width: 160px; object-fit: contain"
    />
  </div>
  <div class="d-flex flex-column ml-3">
    <div class="d-flex flex-row">
      <app-input-text
        id="ref-name-txt"
        placeholder="Name"
        class="mr-3 col-7"
        [value]="reference.name"
        (valueChange)="onNameChanged($event)"
      ></app-input-text>
      <app-input-text
        id="garment-price"
        placeholder="Price"
        class="mr-3 col-2"
        [value]="reference.price?.price?.toString()"
        (valueChange)="onPriceChanged($event)"
      ></app-input-text>
      <app-input-text
        id="garment-currency"
        placeholder="Currency"
        class="mr-3 col-2"
        [value]="reference.price?.currency"
        (valueChange)="onCurrencyChanged($event)"
      ></app-input-text>
    </div>
    <app-input-text
      id="garment-link"
      placeholder="Shop Link"
      class="mr-3 col-7 mb-1 mt-1"
      [value]="reference.garmentLink"
      (valueChange)="onGarmentLinkChanged($event)"
    >
    </app-input-text>
  </div>
  <app-row-buttons
    [reference]="reference"
    (moveRowDown)="this.moveReferenceDown.emit(this.reference)"
    (moveRowUp)="this.moveReferenceUp.emit(this.reference)"
    (removeRow)="this.removeReference.emit(this.reference)"
  ></app-row-buttons>
</div>
