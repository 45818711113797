import { PRODUCT_TYPE } from '../../../data';
import { IPriority } from '../../../data/api';
import { FashionImageResolutionInfo } from './data/fashion-image-size.types';

export interface CreateRequestInputData {
  productType: PRODUCT_TYPE;
}

export function isCreateRequestInputData(
  obj: CreateRequestInputData | UpdateRequestInputData | CloneRequestInputData,
): obj is CreateRequestInputData {
  return obj && 'productType' in obj;
}

export interface UpdateRequestInputData {
  id: string;
}

export function isUpdateRequestInputData(
  obj: CreateRequestInputData | UpdateRequestInputData | CloneRequestInputData,
): obj is UpdateRequestInputData {
  return obj && 'id' in obj;
}

export interface CloneRequestInputData {
  cloneRequestId: string;
}

export function isCloneRequestInputData(
  obj: CreateRequestInputData | UpdateRequestInputData | CloneRequestInputData,
): obj is CreateRequestInputData {
  return obj && 'cloneRequestId' in obj;
}

export interface CreateRequestCommonData {
  id?: string;
  name?: string;
  sku?: string;
  dueDate?: Date;
  priority?: IPriority;
  size?: FashionImageResolutionInfo;
  dataValid: boolean;
}
