import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationHasTagKeyDTO } from '@reactivereality/cs-api-sdk';
import {
  IFashionImageGeneration,
  IFashionImageObjectItem,
} from 'projects/content-service-cms/src/app/core/interfaces/fashion-image-generation.interface';
import { ICustomerService } from 'projects/content-service-cms/src/app/customer';
import {
  IFileService,
  IFileUploadService,
  IGarmentService,
  IProductService,
  IRequestDetail,
  IRequestService,
} from 'projects/content-service-cms/src/app/data';
import { ILogger } from 'projects/content-service-cms/src/app/logging';
import { firstValueFrom } from 'rxjs';
import {
  CreateRequestCommonData,
  CreateRequestInputData,
} from '../create-request-input.interface';
import { CreateRequestModalStore } from '../create-request-modal.state';
import { FashionImageResolutionTypes } from '../data/fashion-image-size.types';
import { CreateRequestBaseService } from './create-request-base';
import { ITagService } from 'projects/content-service-cms/src/app/data/api';
import { Store } from '@ngrx/store';
import { IAppState } from 'projects/content-service-cms/src/app/app.state';
import { navigateToAction } from 'projects/content-service-cms/src/app/navigation/navigation.actions';

@Injectable({
  providedIn: 'root',
})
export class CreateOutfit2DService extends CreateRequestBaseService {
  private readonly OUTFIT_2D_FILENAME = 'outfit2d-manifest.json';
  private readonly OUTFIT_2D_PREVIEW_FILENAME = 'outfit2d-preview.png';

  constructor(
    private _http: HttpClient,
    private _fileService: IFileService<any>,
    requestService: IRequestService,
    private garmentService: IGarmentService,
    private _logger: ILogger,
    private customerService: ICustomerService,
    productService: IProductService,
    private _fileUploadService: IFileUploadService,
    tagService: ITagService,
    private appState: Store<IAppState>,
  ) {
    super(requestService, productService, tagService);
  }

  public readFashionImageGenerator = async (
    request: IRequestDetail,
  ): Promise<IFashionImageGeneration | null> => {
    const files = await this._fileService.getRequestFiles(
      request.id,
      JSON.stringify({
        name: this.OUTFIT_2D_FILENAME,
      }),
    );
    const inputFile = files.results.length > 0 ? files.results[0] : undefined;
    if (inputFile) {
      return await firstValueFrom(
        this._http.get<IFashionImageGeneration>(inputFile.url, {
          responseType: 'json',
        }),
      );
    }
  };

  public buildFashionImageGenerator = async (
    store: CreateRequestModalStore,
    mustBeValid: boolean,
  ): Promise<IFashionImageGeneration | null> => {
    const current = store.current();
    let order = -1;
    current.dressingRoom.garments.forEach((g) => {
      const webResult = g.results.find((r) => r.format === 'web');
      if (!webResult) {
        throw new Error(
          `Failed to handle configuration ${g.id}, no web result`,
        );
      }
      const file = webResult.files.find((o) =>
        o.name.includes('garment.garment'),
      );
      if (!file) {
        throw new Error(
          `Failed to handle configuration ${g.id}, invalid web result, missing garment.garment file`,
        );
      }
    });
    const garmentInputs = current.dressingRoom.garments.map((garment) => {
      const webResult = garment.results.find((r) => r.format === 'web');
      const file = webResult.files.find((o) =>
        o.name.includes('garment.garment'),
      );
      order = order + 1;
      const tuckedInGarment = current.dressingRoom.tuckedInGarment;
      const tuckedIn = tuckedInGarment
        ? tuckedInGarment.id === garment.id
          ? true
          : false
        : null;
      return {
        configurationId: garment.id,
        resultId: webResult.id,
        fileName: file.name,
        isPublic: garment.isPublic,
        tuckedIn: tuckedIn,
        order,
      } as IFashionImageObjectItem;
    });

    const avatarWebResult = current.dressingRoom.avatar.results.find(
      (r) => r.format === 'web',
    );
    if (!avatarWebResult && mustBeValid) {
      throw new Error(
        `Failed to handle configuration ${current.dressingRoom.avatar.id}, no web result`,
      );
    }
    const avatarWebResultFile = avatarWebResult.files.find((f) =>
      f.name.includes('avatar.avatar'),
    );
    if (avatarWebResult && !avatarWebResultFile && mustBeValid) {
      throw new Error(
        `Failed to handle configuration ${current.dressingRoom.avatar.id}, invalid web result, missing avatar.avatar file`,
      );
    }

    const avatar = avatarWebResultFile
      ? {
          configurationId: current.dressingRoom.avatar.id,
          resultId: avatarWebResult.id,
          fileName: avatarWebResultFile.name,
          isPublic: current.dressingRoom.avatar.isPublic,
        }
      : null;

    return {
      fashionImageGeneration: {
        backgroundColor: {
          r: 1,
          g: 1,
          b: 1,
        },
        slots: [
          {
            type: 'TryOn',
            garmentUrls: garmentInputs,
            frame: {
              left: 0,
              top: 0,
              height: current.requestCommonData.size?.height
                ? current.requestCommonData.size.height
                : 1920,
              width: current.requestCommonData.size?.width
                ? current.requestCommonData.size.width
                : 1080,
            },
            scalingMode: 'AspectFill',
            avatarUrl: avatar,
            backgroundHidden: false,
            zoomToAvatar: false,
          },
        ],
        size: {
          height: current.requestCommonData.size?.height
            ? current.requestCommonData.size.height
            : 1920,
          width: current.requestCommonData.size?.width
            ? current.requestCommonData.size.width
            : 1080,
        },
      },
    };
  };

  prepareCreateNew = async (
    inputData: CreateRequestInputData,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    store.clearCreateRequestCommonData();
    store.clearExistingRequest();
    store.setAvatar(null);
    store.setProductType(inputData.productType);
    store.setFeatures({
      supportDraft: true,
      supportDueDate: false,
      supportPriority: false,
      supportSize: true,
    });
  };

  prepareDraftEdit = async (
    request: IRequestDetail,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    const priorities = this._requestService.allPriorities$.value;
    const fashionImage = await this.readFashionImageGenerator(request);

    this._logger.debug(`Fashion image generator loaded`, fashionImage);

    const requestCommonData: CreateRequestCommonData = {
      name: request.name,
      sku: request.sku,
      priority: request.priority
        ? priorities.find((p) => p.id === request.priority)
        : undefined,
      dueDate: request.dueAt ? new Date(request.dueAt * 1000) : undefined,
      dataValid: true,
      size: fashionImage
        ? FashionImageResolutionTypes.find(
            (t) =>
              t.height === fashionImage.fashionImageGeneration.size.height &&
              t.width === fashionImage.fashionImageGeneration.size.width,
          )
        : null,
    };

    store.changeCreateRequestCommonData(requestCommonData);
    store.setTags(
      request.tags.map((v: ConfigurationHasTagKeyDTO) => {
        return {
          id: v.tagKeyId,
          name: v.tagKeyName,
          color: v.tagKeyColor,
          description: v.tagKeyDescription,
          displayTemplate: v.tagKeyDisplayTemplate,
          jsonSchema: v.tagKeyJsonSchema,
        };
      }),
    );

    const { skip_manual_step }: { skip_manual_step: boolean | undefined } =
      (request.metadata as any) ?? {};

    if (skip_manual_step === true) {
      store.changeSkipManualStep(true);
    } else {
      store.changeSkipManualStep(false);
    }

    store.setExistingRequest(request);
    store.setProductType(request.productTypeId);
    store.setFeatures({
      supportDraft: true,
      supportDueDate: false,
      supportPriority: false,
      supportSize: true,
    });

    const avatarID =
      fashionImage?.fashionImageGeneration?.slots[0]?.avatarUrl
        ?.configurationId;

    if (avatarID) {
      this._logger.info('Setting avatar:', avatarID);
      const avatarRequest = await this._requestService.getRequestByID(avatarID);
      store.setAvatar({
        ...avatarRequest,
        isPublic:
          fashionImage.fashionImageGeneration.slots[0].avatarUrl.isPublic,
      });
    }

    const garments =
      fashionImage?.fashionImageGeneration?.slots[0]?.garmentUrls;
    if (garments) {
      this._logger.debug(`Garments: `, garments);
      const resolved = (
        await Promise.all(
          garments
            .map((g) => ({
              dto: this._requestService.getRequestByID(g.configurationId),
              garment: g,
            }))
            .map(async ({ dto, garment }) => {
              try {
                return await this.garmentService.mapRequestsToGarments(
                  await dto,
                  {
                    dressed: true,
                    isPublic: garment.isPublic,
                    tuckedIn: garment.tuckedIn,
                  },
                );
              } catch (e) {
                this._logger.warn(`Failed to load garment!`, e);
                return undefined;
              }
            }),
        )
      ).filter((v) => v !== undefined);
      this._logger.debug(`Resolved Garments: `, resolved);
      const tuckedInItem = resolved.find((o) => o.tuckedIn === true);
      store.setTuckedInGarment(tuckedInItem);
      store.setSelectedGarments(resolved);
      store.setGarments(resolved);
      store.refreshRoomNow(true);
    }
  };

  prepareClone = async (
    request: IRequestDetail,
    store: CreateRequestModalStore,
  ): Promise<void> => {
    store.clearCreateRequestCommonData();
    store.clearExistingRequest();
    store.setProductType(request.productTypeId);

    store.setFeatures({
      supportDraft: true,
      supportDueDate: false,
      supportPriority: false,
      supportSize: true,
    });

    const priorities = this._requestService.allPriorities$.value;
    const fashionImage = await this.readFashionImageGenerator(request);

    this._logger.debug(`Fashion image generator loaded`, fashionImage);

    const requestCommonData: CreateRequestCommonData = {
      name: [request.name, 'COPY'].join(' '),
      priority: request.priority
        ? priorities.find((p) => p.id === request.priority)
        : undefined,
      dueDate: request.dueAt ? new Date(request.dueAt * 1000) : undefined,
      dataValid: true,
      size: fashionImage
        ? FashionImageResolutionTypes.find(
            (t) =>
              t.height === fashionImage.fashionImageGeneration.size.height &&
              t.width === fashionImage.fashionImageGeneration.size.width,
          )
        : null,
    };

    store.changeCreateRequestCommonData(requestCommonData);
    store.setTags(
      request.tags.map((v: ConfigurationHasTagKeyDTO) => {
        return {
          id: v.tagKeyId,
          name: v.tagKeyName,
          color: v.tagKeyColor,
          description: v.tagKeyDescription,
          displayTemplate: v.tagKeyDisplayTemplate,
          jsonSchema: v.tagKeyJsonSchema,
        };
      }),
    );

    const { skip_manual_step }: { skip_manual_step: boolean | undefined } =
      (request.metadata as any) ?? {};

    if (skip_manual_step === true) {
      store.changeSkipManualStep(true);
    } else {
      store.changeSkipManualStep(false);
    }

    const avatarID =
      fashionImage?.fashionImageGeneration?.slots[0]?.avatarUrl
        ?.configurationId;
    if (avatarID) {
      const avatarRequest = await this._requestService.getRequestByID(avatarID);
      store.setAvatar({
        ...avatarRequest,
        isPublic:
          fashionImage.fashionImageGeneration.slots[0].avatarUrl.isPublic,
      });
    }

    const garments =
      fashionImage?.fashionImageGeneration?.slots[0]?.garmentUrls;
    if (garments) {
      this._logger.debug(`Garments: `, garments);
      const resolved = (
        await Promise.all(
          garments
            .map((g) => ({
              dto: this._requestService.getRequestByID(g.configurationId),
              garment: g,
            }))
            .map(async ({ dto, garment }) => {
              try {
                return await this.garmentService.mapRequestsToGarments(
                  await dto,
                  {
                    dressed: true,
                    isPublic: garment.isPublic,
                  },
                );
              } catch (e) {
                this._logger.warn(`Failed to load garment!`, e);
                return undefined;
              }
            }),
        )
      ).filter((v) => v !== undefined);
      this._logger.debug(`Resolved Garments: `, resolved);
      store.setSelectedGarments(resolved);
      store.setGarments(resolved);
      store.refreshRoomNow(true);

      this.appState.dispatch(navigateToAction({ route: ['requests'] }));
    }
  };

  createRequest = async (
    store: CreateRequestModalStore,
    previewImage: string,
    asDraft: boolean,
  ): Promise<void> => {
    this._logger.debug(`Creating/Updating Outfit 2D request ...`);
    const customer = await this.customerService.currentCustomer();
    const state = store.current();
    const fashionImage = await this.buildFashionImageGenerator(store, !asDraft);

    let productId = '';

    if (!state.existingRequest) {
      const product = await this.createProduct(
        customer,
        state.requestCommonData,
        state.productType,
      );
      productId = product.id;
    } else {
      productId = state.existingRequest.productId;
    }

    const request = this.getRequestUpdate(state.requestCommonData, productId);
    request.metadata = {
      skip_manual_step: state.skipManualStep,
    } as IRequestDetail['metadata'];

    if (state.existingRequest) {
      request.id = state.existingRequest.id;
    }

    const front = await this.updateRequest(request);

    await this.updateTags(front, state.tags);

    if (state.existingRequest) {
      const files = await this._fileService.getRequestFiles(request.id);
      const outfitFile = files.results.find(
        (o) => o.name === this.OUTFIT_2D_FILENAME,
      );
      const previewFile = files.results.find(
        (o) => o.name === this.OUTFIT_2D_PREVIEW_FILENAME,
      );
      if (outfitFile) {
        await this._fileService.deleteFile(outfitFile);
      }
      if (previewFile) {
        await this._fileService.deleteFile(previewFile);
      }
    }

    await this._fileUploadService.uploadJsonFile(
      {
        id: '',
        configurationId: request.id,
        fileName: this.OUTFIT_2D_FILENAME,
        typeId: 0,
        mimeType: 'application/json',
      },
      fashionImage,
    );

    await this._fileUploadService.uploadBase64File(
      {
        id: '',
        configurationId: request.id,
        fileName: this.OUTFIT_2D_PREVIEW_FILENAME,
        typeId: 36,
        mimeType: 'image/png',
      },
      previewImage,
    );

    if (!asDraft) {
      await this._requestService.setReady(request.id);
    }
  };
}
