import { Pipe, PipeTransform } from '@angular/core';
import { RR_THUMBNAIL } from 'projects/web-ui-component-library/src';
import { PRODUCT_TYPE, ProductHelper } from '../data';

@Pipe({ name: 'productType', standalone: true })
export class ProductTypePipe implements PipeTransform {
  transform(type: PRODUCT_TYPE) {
    return ProductHelper.mapProductTypeFromEnumToStringName(type);
  }
}

@Pipe({ name: 'garmentThumbnailAvailable', standalone: true })
export class GarmentThumbnailPipe implements PipeTransform {
  transform(url: string) {
    if (url === undefined || url === null) {
      return RR_THUMBNAIL;
    } else {
      return url;
    }
  }
}

@Pipe({ name: 'avatarThumbnailAvailable', standalone: true })
export class AvatarThumbnailPipe implements PipeTransform {
  transform(url: string) {
    if (url === undefined || url === null) {
      return '/assets/img/avatar-placeholder.png';
    } else {
      return url;
    }
  }
}
