<div class="d-flex flex-row">
  <img src="{{ reference.thumbnail }}" class="thumbnail mb-3 mr-3" height="160px" />
  <div class="d-flex flex-column">
    <b>{{ reference.name }}</b>
    <span><b>Reference</b> {{ reference.reference }}</span>
    <span><b>CS-ID:</b> {{ reference.id }}</span>
    <span
      ><b>Outfit: </b
      ><a href="{{ reference.resultUrl }}" target="_blank">Download </a> or
      <a href="/requests/{{ reference.id }}" target="_blank"> Open Request</a>
    </span>
  </div>
  <app-row-buttons
    [reference]="reference"
    (moveRowDown)="this.moveReferenceDown.emit(this.reference)"
    (moveRowUp)="this.moveReferenceUp.emit(this.reference)"
    (removeRow)="this.removeReference.emit(this.reference)"
  ></app-row-buttons>
</div>
