import { Provider } from '@angular/core';
import { GarmentSelectionService } from './garment-selection/services/garment-selection.service';
import { IGarmentSelectionService } from './garment-selection/services/i-garment-selection.service';

export const GeneralComponents = [];

export const GeneralProviders: Provider[] = [
  {
    provide: IGarmentSelectionService,
    useClass: GarmentSelectionService,
  },
];
