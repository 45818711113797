import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILogger } from '../../../logging';

import { ISceneService, ViewerMode, IScene as WebshopScene } from '../api';
import { IAsset, StaticAsset } from '@reactivereality/pictofit-web-sdk';

import { HttpClient } from '@angular/common/http';
import { createSceneAsset } from '../model/WebshopAssets';

@Injectable({
  providedIn: 'root',
})
export class SceneService implements ISceneService {
  // All brands loading
  public readonly allScenes_loading$: BehaviorSubject<boolean>;
  public readonly allScenes$: BehaviorSubject<Array<WebshopScene>>;

  private allScenes_query_counter: number = 0;
  private readonly internal_allScenes$: BehaviorSubject<
    Array<WebshopScene> | undefined
  >;

  constructor(private httpClient: HttpClient, private logger: ILogger) {
    // User Avatars
    this.internal_allScenes$ = new BehaviorSubject<Array<WebshopScene>>([]);
    this.allScenes_loading$ = new BehaviorSubject<boolean>(false);

    this.allScenes$ = new BehaviorSubject<Array<WebshopScene>>([]);

    this.internal_allScenes$.subscribe((n) => this.allScenes$.next(n));

    //load default scene
    const sceneAsset = new StaticAsset(
      'https://rrwebsites.blob.core.windows.net/sdks/sample-assets-17/scenes/2D-parallax/street/',
    );

    //this.loadDefaultScene();
    //   this.gqlService.sdk$.subscribe(async (sdk) => {
    //     try {
    //       const result = await sdk.allScenes();
    //       this.internal_allScenes$.next(
    //         (
    //           await Promise.all(
    //             result.scene.map(async (s) => {
    //               if (s.assets.length < 1) {
    //                 return undefined;
    //               }
    //               return {
    //                 id: s.id,
    //                 name: s.name,
    //                 description: s.description,
    //                 supports_2d: s.support_2d,
    //                 supports_3d: s.support_3d,
    //                 asset: await this.urlCache.attachCachedUrlsToAsset(
    //                   s.assets[0].asset
    //                 ),
    //               };
    //             })
    //           )
    //         ).filter((f) => f !== undefined)
    //       );
    //     } catch (e) {
    //       this.logger.error(`Failed to fetch scenes`, e);
    //     }
    //   });
  }

  private toBlob = async (url: string): Promise<Blob> => {
    const response = await this.httpClient
      .get(url, { observe: 'response', responseType: 'blob' })
      .toPromise();

    if (response.status !== 200) {
      const responseText =
        response.body !== null
          ? await response.body.text()
          : 'Unkown response text';
      throw new Error(
        `Failed to fetch url '${url}' for Avatar ${responseText}`,
      );
    }

    if (response.body === null) {
      throw new Error(`Key '${url}' for Avatar resulted in null blob content`);
    }
    return response.body;
  };

  private loadDefaultScene = async (): Promise<void> => {
    //this.internal_allScenes$.next();
  };

  public toSdkAsset = async (
    scene: WebshopScene,
    mode: ViewerMode,
  ): Promise<IAsset> => {
    switch (mode) {
      case ViewerMode.MODE_2D_PARALLAX:
        if (!scene.supports_2d) {
          throw new Error(`Unsupported scene mode!`);
        }
      case ViewerMode.MODE_3D:
        if (!scene.supports_3d) {
          throw new Error(`Unsupported scene mode!`);
        }
    }
    this.logger.debug(`Scene ${scene.name} to asset => `, scene);
    return createSceneAsset(scene.asset, this.httpClient, this.logger);
  };
}
