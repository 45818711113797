export enum FASHION_IMAGE_RESOLUTION {
  SD = 0,
  HD = 1,
  FOUR_K = 2,
  SIX_K = 3,
}

export interface FashionImageResolutionInfo {
  resolution: FASHION_IMAGE_RESOLUTION;
  width: number;
  height: number;
  text: string;
}

export const FashionImageResolutionTypes: FashionImageResolutionInfo[] = [
  {
    resolution: FASHION_IMAGE_RESOLUTION.SD,
    width: 720,
    height: 1280,
    text: '1280x720 (SD)',
  },
  {
    resolution: FASHION_IMAGE_RESOLUTION.HD,
    width: 1080,
    height: 1920,
    text: '1920x1080 (HD)',
  },
  {
    resolution: FASHION_IMAGE_RESOLUTION.FOUR_K,
    width: 2160,
    height: 3840,
    text: '3840x2160 (4K)',
  },
  {
    resolution: FASHION_IMAGE_RESOLUTION.SIX_K,
    width: 4000,
    height: 6000,
    text: '6000x4000 (6K)',
  },
];
