import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { ILogger } from '../../../logging';

import { AssetFiles, IAvatar, IAvatarService, ViewerMode } from '../api';
import { distinct, distinctUntilChanged, filter } from 'rxjs/operators';
import {
  IAsset,
  PersonalisedMannequinCreatorCustomiseParameters,
} from '@reactivereality/pictofit-web-sdk';
import { Avatar3DAsset } from '../model/WebshopAssets';
import { HttpClient } from '@angular/common/http';
import {
  IRequestDetail,
  IRequestResult,
  IRequestService,
  PRODUCT_TYPE,
} from '../../../data';
import {
  ConfigurationDTO,
  ConfigurationPublicOnlyDTO,
} from '@reactivereality/cs-api-sdk';
import * as _ from 'lodash';
import { AvatarDTO, PagingData } from '../../../data/api';
import { NgStorageManagerService } from '../../../core/services/ng-storage-manager.service';
import { NgDiscoveryService } from '../../../core/services/ng-discovery.service';

@Injectable({
  providedIn: 'root',
})
export class AvatarService implements IAvatarService {
  public readonly defaultAvatarId$: BehaviorSubject<string>;

  // All brands loading
  public readonly allAvatars_loading$: BehaviorSubject<boolean>;
  public readonly allAvatars$: Observable<Array<ConfigurationDTO>>;

  private allAvatars_query_counter: number = 0;
  private readonly internal_allAvatars$: BehaviorSubject<
    Array<ConfigurationDTO> | undefined
  >;
  public readonly allPublicAvatars3d$: BehaviorSubject<
    Array<ConfigurationPublicOnlyDTO> | undefined
  > = new BehaviorSubject<Array<ConfigurationPublicOnlyDTO> | undefined>([]);
  public readonly allPublicAvatars2d$: BehaviorSubject<
    Array<ConfigurationPublicOnlyDTO> | undefined
  > = new BehaviorSubject<Array<ConfigurationPublicOnlyDTO> | undefined>([]);
  public readonly allUserAvatars2d$: BehaviorSubject<
    Array<ConfigurationDTO> | undefined
  > = new BehaviorSubject<Array<ConfigurationPublicOnlyDTO> | undefined>([]);
  public readonly allUserAvatars3d$: BehaviorSubject<
    Array<ConfigurationDTO> | undefined
  > = new BehaviorSubject<Array<ConfigurationPublicOnlyDTO> | undefined>([]);
  public readonly userAvatars_loading$: BehaviorSubject<boolean>;
  public readonly userAvatars$: Observable<Array<ConfigurationDTO>>;

  private userAvatars_query_counter: number = 0;
  private readonly internal_userAvatars$: BehaviorSubject<
    Array<IRequestDetail & IRequestResult> | undefined
  >;

  public BASE_API_URL: string;

  constructor(
    private logger: ILogger,
    private httpClient: HttpClient,
    private _discoveryService: NgDiscoveryService,
    private _requestService: IRequestService,
    private _logger: ILogger,
    private storageManager: NgStorageManagerService,
  ) {
    // User Avatars
    this.internal_userAvatars$ = new BehaviorSubject<
      Array<IRequestDetail & IRequestResult> | undefined
    >(undefined);
    this.userAvatars_loading$ = new BehaviorSubject<boolean>(false);

    this.userAvatars$ = this.internal_userAvatars$.pipe(
      filter((v) => v !== undefined),
    ) as Observable<Array<IRequestDetail & IRequestResult>>;

    // Single Garment
    this.internal_allAvatars$ = new BehaviorSubject<
      Array<IRequestDetail & IRequestResult> | undefined
    >(undefined);
    this.allAvatars_loading$ = new BehaviorSubject<boolean>(false);

    this.allAvatars$ = this.internal_allAvatars$.pipe(
      filter((v) => v !== undefined),
      distinct(),
    ) as Observable<Array<IRequestDetail & IRequestResult>>;

    this.defaultAvatarId$ = new BehaviorSubject<string>(undefined);
    this.storageManager
      .getItem$('avatar.currentAvatar', 'local')
      .pipe(distinctUntilChanged())
      .subscribe((currentAvatar) => {
        this.defaultAvatarId$.next(currentAvatar);
      });

    this.defaultAvatarId$.subscribe((id) => {
      this.storageManager.setItem(`avatar.currentAvatar`, id, 'local');
    });

    this.BASE_API_URL =
      this._discoveryService.config.apiConfiguration.baseApiUrl;
    this.loadUserAvatars2d();
    this.loadPublicAvatars2d();
    this.loadUserAvatars3d();
    this.loadPublicAvatars3d();

    combineLatest([this.internal_userAvatars$]).subscribe();
  }
  private async loadPublicAvatars3d() {
    const response = await this._requestService.getPublicRequests(
      '{"productTypeId":"15", "stateId":[4,13]}',
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o),
    );

    let configs = this.internal_allAvatars$.value
      ? [...this.internal_allAvatars$.value, ...configsWithWebResult]
      : configsWithWebResult;
    configs = _.uniqBy(configs, (config) => {
      return config.id;
    });
    this.allPublicAvatars3d$.next(configsWithWebResult);
    this.internal_allAvatars$.next(configs);
  }
  private async loadPublicAvatars2d() {
    const response = await this._requestService.getPublicRequests(
      '{"productTypeId":"6", "stateId":[4,13]}',
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o[0]),
    );

    let configs = this.internal_allAvatars$.value
      ? [...this.internal_allAvatars$.value, ...configsWithWebResult]
      : configsWithWebResult;
    configs = _.uniqBy(configs, (config) => {
      return config.id;
    });
    this.allPublicAvatars2d$.next(configsWithWebResult);
    this.internal_allAvatars$.next(configs);
  }
  private async loadUserAvatars3d() {
    const response = await this._requestService.getRequests(
      '{"productTypeId":"15", "stateId":[4,13]}',
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o),
    );

    let configs = this.internal_allAvatars$.value
      ? [...this.internal_allAvatars$.value, ...configsWithWebResult]
      : configsWithWebResult;
    configs = _.uniqBy(configs, (config) => {
      return config.id;
    });
    this.allUserAvatars3d$.next(configsWithWebResult);
    this.internal_allAvatars$.next(configs);
  }
  private async loadUserAvatars2d() {
    const response = await this._requestService.getRequests(
      '{"productTypeId":"6", "stateId":[4,13]}',
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o),
    );
    let configs = this.internal_allAvatars$.value
      ? [...this.internal_allAvatars$.value, ...configsWithWebResult]
      : configsWithWebResult;
    configs = _.uniqBy(configs, (config) => {
      return config.id;
    });
    this.allUserAvatars2d$.next(configsWithWebResult);
    this.internal_allAvatars$.next(configsWithWebResult);
  }
  private resultHasWebResult(config: ConfigurationDTO): boolean {
    if (config?.results?.find((o) => o?.format?.toLowerCase() === 'web')) {
      return true;
    }
    return false;
  }
  upsertUserAvatar(avatar: IAvatar): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private toBlob = async (url: string): Promise<Blob> => {
    const response = await this.httpClient
      .get(url, { observe: 'response', responseType: 'blob' })
      .toPromise();

    if (response.status !== 200) {
      const responseText =
        response.body !== null
          ? await response.body.text()
          : 'Unkown response text';
      throw new Error(
        `Failed to fetch url '${url}' for Avatar ${responseText}`,
      );
    }

    if (response.body === null) {
      throw new Error(`Key '${url}' for Avatar resulted in null blob content`);
    }
    return response.body;
  };

  public toSdkAsset = async (
    avatar: IRequestDetail & IRequestResult,
    mode: ViewerMode,
  ): Promise<IAsset> => {
    switch (mode) {
      case ViewerMode.MODE_2D_PARALLAX:
        throw new Error(`Unsupported avatar mode!`);
      case ViewerMode.MODE_3D:
        if (Array.isArray(avatar.results)) {
          const webResult = avatar.results.find(
            (o) => o.format.toLowerCase() === 'web',
          );
          const fileList = webResult.files.map((file) => {
            return {
              filename: file.name,
              mimetype: file.mimeType,
              url: file.url,
            };
          }) as AssetFiles;
          return new Avatar3DAsset(fileList, this.httpClient, this.logger);
        }
    }
  };

  public deleteAvatar = async (avatarId: string): Promise<void> => {};

  private getAvatarRequestFilename = (avatar: IAvatar): string => {
    return `${avatar.name}-request.json`;
  };

  private getAvatarThumbnailFilename = (avatar: IAvatar): string => {
    return `${avatar.name}-thumbnail.png`;
  };

  public getAvatarCreationRequest = async (
    avatar: IAvatar,
  ): Promise<PersonalisedMannequinCreatorCustomiseParameters> => {
    if (avatar === undefined || avatar === null) {
      this.logger.error(
        `Requesting avatar creation request of a null/undefined avatar`,
      );
      throw new Error(
        `Requesting avatar creation request of a null/undefined avatar`,
      );
    }

    if (Array.isArray(avatar.asset)) {
      const requestFile = avatar.asset.find(
        (v) => v.filename === this.getAvatarRequestFilename(avatar),
      );

      if (requestFile === null || requestFile === undefined) {
        this.logger.error(
          `Requesting avatar creation request of avatar, but none found`,
          avatar,
        );
        throw new Error(
          `Requesting avatar creation request of avatar but none found`,
        );
      }

      return this.httpClient
        .get<PersonalisedMannequinCreatorCustomiseParameters>(requestFile.url, {
          responseType: 'json',
        })
        .toPromise();
    } else if (typeof avatar.asset === 'object') {
      const requestData = await avatar.asset.getComponent(
        this.getAvatarRequestFilename(avatar),
      );
      return JSON.parse(
        await requestData.text(),
      ) as PersonalisedMannequinCreatorCustomiseParameters;
    } else {
      this.logger.error(
        `Requesting avatar creation request of avatar, without valid asset`,
        avatar.asset,
      );
      throw new Error(
        `Requesting avatar creation request of avatar, without valid asset`,
      );
    }
  };

  public async loadCustomerAvatars2D(
    customerId: string,
    currentPage: number,
    pageSize: number,
  ): Promise<ConfigurationDTO[]> {
    const response = await this._requestService.getRequests(
      `{"productTypeId":"6", "stateId":[4,13], "customerId":"${customerId}"}`,
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o),
    );
    return configsWithWebResult;
  }

  public async loadCustomerAvatars3D(
    customerId: string,
    currentPage: number,
    pageSize: number,
  ): Promise<ConfigurationDTO[]> {
    const response = await this._requestService.getRequests(
      `{"productTypeId":"15", "stateId":[4,13], "customerId":"${customerId}"}`,
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o),
    );
    return configsWithWebResult;
  }
  public async loadPublicAvatars2D(): Promise<ConfigurationDTO[]> {
    const response = await this._requestService.getPublicRequests(
      `{"productTypeId":"6", "stateId":[4,13]}`,
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o),
    );
    return configsWithWebResult;
  }

  public async loadPublicAvatars3D(): Promise<ConfigurationDTO[]> {
    const response = await this._requestService.getPublicRequests(
      `{"productTypeId":"15", "stateId":[4,13]}`,
      1,
      -1,
    );
    const configsWithWebResult = response.configurations.filter((o) =>
      this.resultHasWebResult(o),
    );
    return configsWithWebResult;
  }

  public async getAvatarsForCustomer(
    requestFilter: string,
    productType: PRODUCT_TYPE.AVATAR_2D | PRODUCT_TYPE.AVATAR_3D,
    customerId: string,
    page: number,
    pageSize: number,
    tags: string,
    signal: AbortSignal,
    inlcudeArchived: boolean = true,
  ): Promise<{ avatars: AvatarDTO[]; paging: PagingData }> {
    const customerRequests = await this._requestService.getAllCustomerRequests(
      customerId,
      requestFilter,
      page,
      pageSize,
      tags,
      inlcudeArchived,
      signal,
    );
    return {
      avatars: await this.mapRequestsToAvatars(
        productType,
        customerRequests.configurations,
      ),
      paging: customerRequests.paging,
    };
  }

  private async mapRequestsToAvatars(
    productType: PRODUCT_TYPE.AVATAR_2D | PRODUCT_TYPE.AVATAR_3D,
    requests: ConfigurationPublicOnlyDTO[],
  ): Promise<AvatarDTO[]> {
    const avatars: AvatarDTO[] = [];
    for (const request of requests) {
      if (
        request.storageInTransit != false ||
        request.storageLocation != null
      ) {
        //configuration is archived or a archiving/restoring process is ongoing
        this._logger.debug('Avatar is archived - id: ', request.id);
        const avatar: AvatarDTO = {
          ...request,
          archived: true,
          enabled: false,
          isPublic: false,
        };
        avatars.push(avatar);
        continue;
      }
      let enabled = true;

      if (productType === PRODUCT_TYPE.AVATAR_3D) {
        enabled = ['mobile', 'web'].every((value) => {
          return request.results.find((r) => r.format === value);
        });
      }
      if (productType === PRODUCT_TYPE.AVATAR_2D) {
        enabled = ['web'].every((value) => {
          return request.results.find((r) => r.format === value);
        });
      }

      const avatar: AvatarDTO = {
        ...request,
        isPublic: false,
        enabled: enabled,
        archived:
          request.storageInTransit != false || request.storageLocation != null,
      };
      avatars.push(avatar);
    }
    return avatars;
  }
}
